@import src/styles/colors
@import src/styles/variables

$gap: 20px
$animationDelay: 550ms

.powerbi-page.login
    max-width: max-content
    height: calc(100% + 100px) !important
    margin: auto
    display: flex
    flex-direction: column
    gap: 20px
    justify-content: center
    align-items: center
    transform: scale(1)
    opacity: 1
    transition: all 550ms $animationCurve

    .login-container
        @include disable-selecting
        font-family: $font-family
        border: 1px solid $black
        border-radius: 20px
        display: flex
        width: max-content
        flex-direction: column
        justify-content: center
        align-items: center
        position: relative
        transition: all 550ms $animationCurve

        .headline
            width: 90%
            padding: 30px 15px 20px 15px

            .logo-container
                transition: all 750ms $animationCurve
                transform: translateY(4px)
                margin-bottom: 25px
                width: 100%
                display: flex
                justify-content: center

                h1
                    font-size: 40px
                    color: $yellow
                    line-height: 20px

                    span
                        display: block
                        color: $black
                        font-size: 15px
                        font-weight: 200
                        letter-spacing: 12.9px
                        margin-top: 15px

            .header-text
                font-weight: 500
                color: $black

        &.sso-login
            width: 100%
            padding: 0 50px
            border-width: 0

            .sso-login-button
                width: 100%
                color: white
                font-weight: bold
                font-size: 15px
                border-radius: 20px


        .login-powerbi-logo
            top: 0
            width: 170px
            transform: translateY(-50%)
            position: absolute
            z-index: 3
            display: flex
            justify-content: center
            align-items: center
            transition: all $animationDelay $animationCurve
            animation: firstTimeRender 1s $animationCurve forwards 350ms
            opacity: 0

            img
                width: 80%
                transition: all $animationDelay $animationCurve 350ms
                $shadow-color: $white
                filter: drop-shadow(19px 0 0 $shadow-color) drop-shadow(-20px 0 0 $shadow-color) drop-shadow(0 -20px 0 $shadow-color) drop-shadow(0 20px 0 $shadow-color)

        .login-content
            display: flex
            justify-content: center
            align-items: center
            gap: 20px
            transition: all $animationDelay $animationCurve 350ms
            position: relative

            .forms
                position: relative
                display: flex
                justify-content: center

                .horizontal-movable-forms
                    height: 160px
                    transition: height 750ms $animationCurve
                    position: relative
                    //overflow: hidden
                    padding: 3px 30px 0 30px

                    .left-movable-form
                        transition: all 750ms $animationCurve
                        position: relative
                        transform: translateX(0)

                    .right-movable-form
                        transition: all 750ms $animationCurve
                        position: absolute
                        right: 0
                        top: 0
                        transform: translateX(calc(100% + 60px))
                        height: 50px
                        opacity: 0
                        display: flex
                        flex-direction: column
                        justify-content: flex-start
                        gap: 20px

                        .reset-password-waiting-period
                            width: calc(100% + 50px)
                            text-align: center
                            color: $red

                        .field
                            display: flex
                            flex-direction: column
                            justify-content: center
                            align-items: center
                            gap: 20px

                            .resend-sec-code-button
                                margin-left: 50px

                            >div

                                display: flex
                                justify-content: flex-end
                                gap: 10px

                                .form
                                    height: 50px
                                    width: calc(100% - 100px)

                                .back-to-login-form-button
                                    border: 1px solid $black
                                    width: 40px
                                    height: 50px


                    &.switch-to-right-form
                        .left-movable-form
                            transform: translateX(calc(-100% + -60px))
                            opacity: 0

                        &.switch-to-forget-password-form
                            height: 85px

                            .right-movable-form.forget-password
                                opacity: 1
                                transform: translateX(-30px)

                        &.switch-to-sec-code-form
                            height: 85px

                            .right-movable-form.sec-code
                                opacity: 1
                                transform: translateX(-30px)


                        &.waiting-period
                            height: 110px

                            .right-movable-form
                                .field

                                    .form
                                        height: 50px
                                        width: calc(100% - 100px)
                                        opacity: .5
                                        pointer-events: none

                                        .login-button
                                            background-color: $grey

                .form
                    display: flex
                    justify-content: center
                    align-items: center
                    height: 45 * 2 + 10px
                    transition: all 750ms $animationCurve
                    border: 1px solid $black
                    border-radius: 10px
                    overflow: hidden
                    position: relative

                    .login-button
                        width: 40px
                        height: 101%
                        display: flex
                        justify-content: center
                        align-items: center
                        overflow: hidden
                        position: relative
                        cursor: pointer
                        transition: all 350ms $animationCurve
                        transform: scale(.999)
                        border-left: 1px solid $black
                        background-color: $blue
                        background-position: center
                        background-size: cover

                        &.disabled
                            opacity: .5

                        &:active
                            opacity: .8

                        .material-symbols-outlined
                            color: black
                            z-index: 2
                            font-weight: lighter
                            color: #fff

                    .text-fields
                        display: flex
                        flex-direction: column
                        justify-content: center
                        align-items: center
                        width: 300px
                        background-color: $white

                        .textfield
                            width: 100%
                            position: static

                            &:first-child
                                input
                                    background-color: $white
                                    border-radius: 0

                            &:last-child
                                input
                                    border-radius: 0

                            input
                                padding-left: 20px
                                border-color: transparent !important
                                border-width: 0 !important
                                line-height: 49px
                                background-color: $white

                            .textfield-invalid-feedback-text
                                font-size: 10px
                                text-align: center
                                padding-left: 10px
                                color: $red
                                font-weight: 500
                                transform: translateY(15px) translateX(10px)

                            &.Medium
                                .textfield-invalid-feedback-text
                                    color: $orange
                            &.Strong, &.matched
                                .textfield-invalid-feedback-text
                                    color: $green

                        hr
                            width: 85%
                            height: 0
                            border: 0
                            border-top: 1px solid rgba($black-rgb, .5)

                    &.set-new-password-form
                        position: absolute
                        top: 20px
                        opacity: 0
                        pointer-events: none

                .new-password-strength-hints
                    transition: all 750ms $animationCurve
                    position: absolute
                    bottom: 30px
                    opacity: 0
                    pointer-events: none
                    transform: translateY(100%)
                    width: calc(100% - 60px)
                    padding: 20px
                    z-index: 5
                    background-color: $white
                    border: 1px solid $black
                    border-radius: 10px

                    > ul
                        width: 100%
                        text-align: left
                        list-style-type: none
                        display: flex
                        flex-direction: column
                        gap: 15px

                        > li
                            width: 100%

                            .checkbox
                                width: max-content
                                color: $red

                                .checkbox-icon
                                    border-color: $red

                                &.checked
                                    color: $green

                                    .checkbox-icon
                                        border-color: $green

                                        .material-symbols-outlined
                                            color: $green

                &.show-set-new-password-form
                    .login-form
                        pointer-events: none
                        transform: scale(.95) translateY(-5px)
                        opacity: .5
                        margin-bottom: 10px

                        .login-button
                            background-color: $grey

                    .set-new-password-form
                        top: 10px
                        opacity: 1
                        pointer-events: auto

                    .new-password-strength-hints
                        bottom: 40px
                        opacity: 1
                        pointer-events: auto

                .forget-password-button
                    margin: auto
                    margin-top: 20px
                    margin-bottom: -25px
                    font-size: 12px
                    opacity: .8

                    &:hover
                        opacity: 1



        &.loading-first-time
            border: 1px solid transparent

            .login-powerbi-logo
                top: 50%
                width: 300px
                img
                    animation: loading 3s $animationCurve infinite

            .login-content
                opacity: 0
                transform: scale(.98)


    &.logged-in
        animation: loggedIn $animationDelay $animationCurve forwards 550ms

@keyframes firstTimeRender
    0%
        opacity: 0

    100%
        opacity: 1

@keyframes loading
    70%
        transform: scale(1.1)

@keyframes loggedIn
    0%
        transform: scale(1)
        opacity: 1
    100%
        transform: scale(3)
        opacity: 0